import { useEffect } from 'react';

export const useLockScrolling = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
};

export const useConditionalLockScrolling = () => {
  return {
    lock: () => {
      document.body.style.overflow = 'hidden';
    },
    unlock: () => {
      document.body.style.overflow = 'auto';
    },
  };
};
